

































































































import { Event } from '@/interfaces/event'
import { unexpectedExc } from '@/utils'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapState, mapMutations } from 'vuex'

@Component({
  computed: {
    ...mapState('event', {
      event: 'currentEvent'
    })
  },
  methods: {
    ...mapMutations('message', {
      showSucces: 'SHOW_SUCCESS'
    })
  }
})
export default class EventShare extends Vue {
  // eslint-disable-next-line no-undef
  [index: string]: unknown

  @Prop(Number) readonly pk!: number

  /**
   * Setup
   */
  loading = true
  event!: Event
  showSucces!: CallableFunction

  created (): void {
    this.setupEvent()
  }

  setupEvent (): void {
    this.$store.dispatch('event/getEventDetail', this.pk)
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }

  get joinWithQrUrl (): string {
    if (this.event === null) return ''
    const host = process.env.VUE_APP_WEB_ROOT
    return `${host}/qr/${this.pk}/${this.event.join_token}`
  }

  /**
   * Join with link
   */
  copyJoinLink (): void {
    navigator.clipboard.writeText(this.joinWithQrUrl)
    this.showSucces('Link copied.')
  }

  /**
   * Reset QR code
   */
  resetting = false

  resetQrCode (): void {
    if (this.resetting) return
    this.resetting = true

    this.$store.dispatch('event/resetQrCode')
      .then(() => {
        this.showSucces('Làm mới liên kết thành công.')
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.resetting = false
      })
  }
}
