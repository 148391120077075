


































































































import { EventInvitation, User } from '@/interfaces/user'
import { unexpectedExc } from '@/utils'
import { Vue, Component } from 'vue-property-decorator'
import { mapMutations, mapState } from 'vuex'
import BaseAvatar from '@/components/BaseAvatar.vue'

@Component({
  computed: {
    ...mapState('account', {
      user: 'loggedInUser',
      eventInvitations: 'eventInvitations'
    })
  },
  methods: {
    ...mapMutations('message', {
      showSucces: 'SHOW_SUCCESS'
    })
  },
  components: {
    BaseAvatar
  }
})
export default class Notifications extends Vue {
  /**
   * Setup
   */
  user!: User
  eventInvitations!: EventInvitation[]
  loading = true

  created (): void {
    this.setupNotifications()
  }

  setupNotifications (): void {
    this.$store.dispatch('account/getEventInvitations')
      .then(() => {
        this.eventInvitations.forEach(invitation => {
          this.acceptings[invitation.pk] = false
          this.declinings[invitation.pk] = false
        })
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }

  /**
   * Accept / decline event invitation
   */
  acceptings: { [index: number]: boolean } = {}
  declinings: { [index: number]: boolean } = {}
  showSucces!: CallableFunction

  acceptEventInvitation (invitation: EventInvitation): void {
    if (this.acceptings[invitation.pk]) return
    this.acceptings[invitation.pk] = true

    this.$store.dispatch('account/acceptEventInvitation', invitation)
      .then(() => {
        this.showSucces('Tham gia chuyến đi thành công.')
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.acceptings[invitation.pk] = false
      })
  }

  declineEventInvitation (invitation: EventInvitation): void {
    if (this.declinings[invitation.pk]) return
    this.declinings[invitation.pk] = true

    this.$store.dispatch('account/declineEventInvitation', invitation)
      .then(() => {
        this.showSucces('Từ chối tham gia chuyến đi thành công.')
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.declinings[invitation.pk] = false
      })
  }
}
