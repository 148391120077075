



























































































































import { User } from '@/interfaces/user';
import { Vue, Component } from 'vue-property-decorator'
import { mapMutations, mapState } from 'vuex'
import BaseAvatar from '@/components/BaseAvatar.vue'
import BaseDialogConfirm from '@/components/BaseDialogConfirm.vue'

@Component({
  computed: {
    ...mapState('account', {
      user: 'loggedInUser'
    })
  },
  methods: {
    ...mapMutations('message', {
      showSucces: 'SHOW_SUCCESS'
    })
  },
  components: {
    BaseAvatar,
    BaseDialogConfirm
  }
})
export default class MyInfo extends Vue {
  // eslint-disable-next-line no-undef
  [index: string]: unknown

  /**
   * Setup
   */
  loading = true
  user!: User

  /**
   * Logout
   */
  logoutConfirm = false
  showSucces!: CallableFunction

  logout (): void {
    this.$store.dispatch('account/logout')
      .then(() => {
        this.showSucces('Đăng xuất thành công.')
        this.$router.push({ name: 'Login' })
      })
  }
}
