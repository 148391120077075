















































































































import { Api } from '@/api'
import { SearchUserDetailRes, SearchUsersReq } from '@/interfaces/api/account'
import { debounce, snakeCaseToCamelCase, unexpectedExc } from '@/utils'
import { Vue, Component, Watch } from 'vue-property-decorator'
import BaseAvatar from '@/components/BaseAvatar.vue'
import { EventCreateReq, EventInviteMembersReq } from '@/interfaces/event'
import { assertErrCode, status } from '@/utils/status-codes'
import { EventDetailRes } from '@/interfaces/api/event'
import { mapMutations } from 'vuex'

@Component({
  components: {
    BaseAvatar
  },
  methods: {
    ...mapMutations('message', {
      showSucces: 'SHOW_SUCCESS'
    })
  }
})
export default class EventCreate extends Vue {
  // eslint-disable-next-line no-undef
  [index: string]: unknown

  /**
   * Search for users
   */
  text = ''
  searching = false
  chosenUser: SearchUserDetailRes | null = null
  fetchedUsers: SearchUserDetailRes[] = []

  // eslint-disable-next-line
  get autocompleteItems () {
    return this.fetchedUsers.map(user => ({
      text: user.email,
      value: user
    }))
  }

  @Watch('text')
  onSearch (text: string): void {
    debounce(this.searchUsers, 200)(text)
  }

  searchUsers (text: string): void {
    if (this.searching) return
    if (text === '' || text === null) {
      this.fetchedUsers = []
      return
    }

    this.searching = true

    const params: SearchUsersReq = {
      nickname_or_email__icontains: text
    }

    Api.account.searchUsers(params)
      .then(data => {
        const users = data.results || []
        const addedEmails = this.membersToAdd.map(member => member.email)
        this.fetchedUsers = users.filter(user => !addedEmails.includes(user.email))
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.searching = false
      })
  }

  /**
   * Add members
   */
  membersToAdd: SearchUserDetailRes[] = []

  @Watch('chosenUser')
  onChooseUser (user: SearchUserDetailRes | null): void {
    if (
      user !== null &&
      !this.membersToAdd.map(member => member.email).includes(user.email)
    ) {
      this.membersToAdd.push(user)
    }
    this.text = ''
    // @ts-expect-error don't care
    this.$refs.searchInput.clearableCallback()
  }

  removeMember (email: string): void {
    this.membersToAdd = this.membersToAdd.filter(member => member.email !== email)
  }

  /**
   * Create event
   */
  name = ''
  nameErrs = []
  creating = false
  showSucces!: CallableFunction

  onCreateEventSuccess (event: EventDetailRes): void {
    this.showSucces('Tạo chuyến đi thành công.')
    this.$router.push({
      name: 'EventDetail',
      params: {
        pk: event.pk.toString()
      }
    })
  }

  createEvent (): void {
    if (this.creating) return

    const payload: EventCreateReq = {
      name: this.name
    }
    this.$store.dispatch('event/createEvent', payload)
      .then((event: EventDetailRes) => {
        const memberEmails = this.membersToAdd.map(member => member.email)

        if (memberEmails.length === 0) {
          this.onCreateEventSuccess(event)
        } else {
          const url = event.extra_action_urls.invite_members
          const inviteMembersPayload: EventInviteMembersReq = {
            member_emails: memberEmails
          }
          Vue.axios.post(url, inviteMembersPayload)
            .then(() => {
              this.onCreateEventSuccess(event)
            })
            .catch(unexpectedExc)
        }
      })
      .catch(err => {
        if (assertErrCode(err, status.HTTP_400_BAD_REQUEST)) {
          const data = err.response.data
          Object.entries(data).forEach(([field, errMsgs]) => {
            const attr = `${snakeCaseToCamelCase(field)}Errs`
            this[attr] = errMsgs
          })
        } else {
          unexpectedExc(err)
        }
      })
      .finally(() => {
        this.creating = false
      })
  }
}
