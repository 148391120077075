

















































































import { Api } from '@/api'
import { PaginatedRes } from '@/interfaces/api/common'
import { Settlement } from '@/interfaces/event'
import { unexpectedExc } from '@/utils'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import BaseDialogConfirm from '@/components/BaseDialogConfirm.vue'

@Component({
  methods: {
    ...mapMutations('message', {
      showSuccess: 'SHOW_SUCCESS'
    })
  },
  components: {
    BaseDialogConfirm
  }
})
export default class SettlePreview extends Vue {
  @Prop(Number) readonly pk!: number

  /**
   * Setup
   */
  loading = true
  settlements: Settlement[] = []

  created (): void {
    this.setupSettlements()
  }

  setupSettlements (): void {
    Api.event.getPreviewSettlements(this.pk)
      .then(data => {
        this.settlements = data.results || []
        delete data.results
        this.pagination = data
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }

  /**
   * Pagination
   */
  page = 1
  pagination!: PaginatedRes
  itemsPerPage = 10

  get paginationLength (): number {
    return Math.ceil(this.pagination.count / this.itemsPerPage)
  }

  @Watch('page')
  onPageChange (pageNum: number): void {
    const offset = (pageNum - 1) * this.itemsPerPage
    const params = {
      event: this.pk,
      limit: this.itemsPerPage,
      offset
    }

    this.loading = true
    Api.event.getPreviewSettlements(this.pk, params)
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }

  nextPage (): void {
    if (this.pagination.next !== null) {
      this.loading = true
      Vue.axios.get(this.pagination.next)
        .catch(unexpectedExc)
        .finally(() => {
          this.loading = false
        })
    }
  }

  previousPage (): void {
    if (this.pagination.previous !== null) {
      this.loading = true
      Vue.axios.get(this.pagination.previous)
        .catch(unexpectedExc)
        .finally(() => {
          this.loading = false
        })
    }
  }

  /**
   * Settle
   */
  settleConfirmDialog = false
  settling = false
  showSuccess!: CallableFunction

  settle (): void {
    if (this.settling) return
    this.settling = true

    Api.event.settle(this.pk)
      .then(() => {
        this.showSuccess('Chốt sổ thành công.')
        this.$router.push({
          name: 'EventDetail',
          params: {
            pk: this.pk.toString()
          }
        })
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.settling = false
      })
  }
}
