




















































import { Api } from '@/api'
import { ChangePasswordReq } from '@/interfaces/api/account'
import { User } from '@/interfaces/user'
import { unexpectedExc } from '@/utils'
import { assertErrCode, status } from '@/utils/status-codes'
import { Vue, Component } from 'vue-property-decorator'
import { mapMutations, mapState } from 'vuex'

@Component({
  computed: {
    ...mapState('account', {
      user: 'loggedInUser'
    })
  },
  methods: {
    ...mapMutations('message', {
      showSuccess: 'SHOW_SUCCESS'
    })
  }
})
export default class MyInfoChangePassword extends Vue {
  // eslint-disable-next-line no-undef
  [index: string]: unknown

  /**
   * Setup
   */
  user!: User

  /**
   * Change name
   */
  newPassword = ''
  newPasswordErrs: string[] = []
  showNewPassword = false

  currentPassword = ''
  currentPasswordErrs: string[] = []
  showCurrentPassword = false

  loading = false
  showSuccess!: CallableFunction

  changePassword (): void {
    if (this.loading) return
    this.loading = true

    const payload: ChangePasswordReq = {
      new_password: this.newPassword,
      current_password: this.currentPassword
    }

    Api.account.changePassword(payload)
      .then(() => {
        this.showSuccess('Đổi mật khẩu thành công.')
        this.$router.push({ name: 'MyInfo' })
      })
      .catch(err => {
        const data = err.response.data
        if (assertErrCode(err, status.HTTP_400_BAD_REQUEST)) {
          this.currentPasswordErrs = data.current_password || []
          this.newPasswordErrs = data.new_password || []
        } else if (assertErrCode(err, status.HTTP_403_FORBIDDEN)) {
          this.currentPasswordErrs = data.detail
          this.newPasswordErrs = data.new_password || []
        } else {
          unexpectedExc(err)
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
}
