







































































































































































































































import { Event, EventInviteMembersReq } from '@/interfaces/event'
import { debounce, unexpectedExc } from '@/utils'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapState, mapMutations } from 'vuex'
import { User } from '@/interfaces/user'
import { CancelEventInvitationReq, EventInivitationsListRes, EventInvitationDetailRes, RemoveMembersReq } from '@/interfaces/api/event'
import BaseAvatar from '@/components/BaseAvatar.vue'
import BaseDialogConfirm from '@/components/BaseDialogConfirm.vue'
import { AxiosRequestConfig } from 'axios'
import { Api } from '@/api'
import { SearchUserDetailRes, SearchUsersReq } from '@/interfaces/api/account'

@Component({
  computed: {
    ...mapState('event', {
      event: 'currentEvent'
    }),
    ...mapState('account', [
      'loggedInUser'
    ])
  },
  methods: {
    ...mapMutations('message', {
      showSucces: 'SHOW_SUCCESS'
    })
  },
  components: {
    BaseAvatar,
    BaseDialogConfirm
  }
})
export default class EventMembers extends Vue {
  @Prop(Number) readonly pk!: number

  /**
   * Setup
   */
  loadingEvent = true
  event!: Event
  loggedInUser!: User
  showSucces!: CallableFunction

  created (): void {
    this.setupEvent()
  }

  setupEvent (): void {
    this.$store.dispatch('event/getEventDetail', this.pk)
      .then(() => {
        this.setupInvitations()
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loadingEvent = false
      })
  }

  /**
   * Remove member
   */
  removing = false
  memberToRemove: User | null = null
  confirmRemoveDialog = false

  prepareRemove (member: User): void {
    this.memberToRemove = member
    this.confirmRemoveDialog = true
  }

  removeMember (): void {
    if (this.removing || this.memberToRemove === null) return
    this.removing = true

    const payload: RemoveMembersReq = {
      member_pks: [this.memberToRemove.pk]
    }
    this.$store.dispatch('event/removeMembers', payload)
      .then(() => {
        this.showSucces('Xóa thành viên thành công.')
        this.confirmRemoveDialog = false
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.removing = false
      })
  }

  /**
   * Invitations
   */
  loadingInvitations = true
  invitations: EventInvitationDetailRes[] = []

  setupInvitations (): void {
    const params: AxiosRequestConfig['params'] = {
      status__in: 'pending'
    }

    Vue.axios.get(this.event.invitations_url, { params })
      .then(res => {
        const data: EventInivitationsListRes = res.data
        this.invitations = data.results || []
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loadingInvitations = false
      })
  }

  /**
   * Search for users to invite
   */
  text = ''
  searching = false
  chosenUser: SearchUserDetailRes | null = null
  fetchedUsers: SearchUserDetailRes[] = []

  // eslint-disable-next-line
  get autocompleteItems () {
    return this.fetchedUsers.map(user => ({
      text: user.email,
      value: user
    }))
  }

  @Watch('text')
  onSearch (text: string): void {
    debounce(this.searchUsers, 200)(text)
  }

  searchUsers (text: string): void {
    if (this.searching) return
    if (text === '' || text === null) {
      this.fetchedUsers = []
      return
    }

    this.searching = true

    const memberEmails = this.event.members.map(member => member.email)
    const invitedEmails = this.invitations.map(invitation => invitation.user.email)
    const excludeEmails = memberEmails.concat(invitedEmails)

    const params: SearchUsersReq = {
      nickname_or_email__icontains: text,
      exclude_emails: excludeEmails.join(',')
    }

    Api.account.searchUsers(params)
      .then(data => {
        this.fetchedUsers = data.results || []
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.searching = false
      })
  }

  /**
   * Invite user
   */
  @Watch('chosenUser')
  onChoosingUser (user: SearchUserDetailRes | null): void {
    if (user === null) return

    const url = this.event.extra_action_urls.invite_members
    const payload: EventInviteMembersReq = {
      member_emails: [user.email]
    }

    Vue.axios.post(url, payload)
      .then(() => {
        this.showSucces('Thêm lời mời thành công.')
        this.setupInvitations()
        this.text = ''
        this.chosenUser = null
      })
      .catch(unexpectedExc)
  }

  /**
   * Remove invitation
   */
  removingInvitation = false
  confirmRemoveInvitationDialog = false
  invitationToRemove: EventInvitationDetailRes | null = null

  prepareRemoveInvitation (invitation: EventInvitationDetailRes): void {
    this.invitationToRemove = invitation
    this.confirmRemoveInvitationDialog = true
  }

  removeInvitation (): void {
    if (this.removingInvitation || this.invitationToRemove === null) return
    this.removingInvitation = true

    const payload: CancelEventInvitationReq = {
      member_emails: [this.invitationToRemove.user.email]
    }

    Vue.axios.post(this.event.extra_action_urls.cancel_invite_members, payload)
      .then(() => {
        this.showSucces('Hủy lời mời thành công.')
        this.setupInvitations()
        this.confirmRemoveInvitationDialog = false
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.removingInvitation = false
      })
  }
}
