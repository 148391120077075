
































import { Api } from '@/api'
import { snakeCaseToCamelCase } from '@/utils'
import { assertErrCode, status } from '@/utils/status-codes'
import { Vue, Component } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import BaseAuth from './BaseAuth.vue'

@Component({
  methods: {
    ...mapMutations('message', {
      showSuccess: 'SHOW_SUCCESS'
    })
  },
  components: {
    BaseAuth
  }
})
export default class NewPassword extends Vue {
  // eslint-disable-next-line no-undef
  [index: string]: unknown

  token = ''
  uid = ''
  showSuccess!: CallableFunction

  created (): void {
    this.token = (this.$route.query.token as string) || ''
    this.uid = (this.$route.query.uid as string) || ''
  }

  password = ''
  passwordErrs: string[] = []
  loading = false
  show = false

  submit (): void {
    if (this.loading) return
    this.loading = true

    Api.account.resetPassword({
      uid: this.uid,
      token: this.token,
      password: this.password
    })
      .then(() => {
        this.showSuccess('Đặt lại mật khẩu thành công, hãy đăng nhập với mật khẩu mới.')
        this.$router.push({ name: 'Login' })
      })
      .catch(err => {
        if (assertErrCode(err, status.HTTP_400_BAD_REQUEST)) {
          const data = err.response.data
          Object.entries(data).forEach(([field, errMsgs]) => {
            const attr = `${snakeCaseToCamelCase(field)}Errs`
            this[attr] = errMsgs
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
}
