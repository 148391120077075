

























































































































































































































































































































































































































































































































































































































































































import { PaginatedRes } from '@/interfaces/api/common'
import { SettlementDetailRes, SettlementListRes } from '@/interfaces/api/settlement'
import { TransactionCreateReq } from '@/interfaces/api/transaction'
import { Event, EventChartInfo } from '@/interfaces/event'
import { Transaction } from '@/interfaces/transaction'
import { User } from '@/interfaces/user'
import { formatDatetime, unexpectedExc } from '@/utils'
import { assertErrCode, status } from '@/utils/status-codes'
import { AxiosRequestConfig } from 'axios'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapMutations, mapState } from 'vuex'
import BaseDialogConfirm from '@/components/BaseDialogConfirm.vue'

@Component({
  computed: {
    ...mapState('event', {
      event: 'currentEvent'
    })
  },
  methods: {
    ...mapMutations('message', {
      showSuccess: 'SHOW_SUCCESS'
    })
  },
  components: {
    BaseDialogConfirm
  }
})
export default class EventDetail extends Vue {
  // eslint-disable-next-line no-undef
  [index: string]: unknown

  @Prop(Number) readonly pk!: number

  /**
   * Setup
   */
  event!: Event
  loading = true
  formatDatetime = formatDatetime

  created (): void {
    this.setupEvent()
  }

  setupEvent (): void {
    this.$store.dispatch('event/getEventDetail', this.pk)
      .then(this.setupEventDetail)
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }

  setupEventDetail (): void {
    this.setupChartInfo()
    this.setupRecentTransactions()

    if (this.event.is_settled) {
      this.setupSettlements()
    }
  }

  get membersCount (): number {
    return this.event.members.length
  }

  /**
   * Menu items (three dots vertical)
   */
  get menuItems (): {
    icon: string;
    text: string;
    onClick: CallableFunction;
  }[] {
    const items = [
      {
        icon: 'pencil-outline',
        text: 'Chỉnh sửa',
        onClick: () => {
          this.$router.push({
            name: 'EventUpdate',
            params: {
              pk: this.event.pk.toString()
            }
          })
        }
      },
      {
        icon: 'share-variant-outline',
        text: 'Chia sẻ',
        onClick: () => {
          this.$router.push({
            name: 'EventShare',
            params: {
              pk: this.event.pk.toString()
            }
          })
        }
      },
      {
        icon: 'account-multiple-outline',
        text: 'Thành viên',
        onClick: () => {
          this.$router.push({
            name: 'EventMembers',
            params: {
              pk: this.event.pk.toString()
            }
          })
        }
      }
    ]

    if (!this.event.is_settled) {
      items.push({
        icon: 'calculator',
        text: 'Chốt sổ',
        onClick: () => {
          this.$router.push({
            name: 'SettlePreview',
            params: {
              pk: this.event.pk.toString()
            }
          })
        }
      })
    }

    items.push({
      icon: 'delete-outline',
      text: 'Xóa chuyến đi',
      onClick: () => {
        this.confirmDeleteEventDialog = true
      }
    })

    return items
  }

  /**
   * Chart info (Tình hình thu chi)
   */
  chartInfo: EventChartInfo | null = null
  loadingChartInfo = false

  setupChartInfo (): void {
    this.loadingChartInfo = true

    Vue.axios.get(this.event.extra_action_urls.chart_info)
      .then(res => {
        this.chartInfo = res.data
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loadingChartInfo = false
      })
  }

  get chartHasData (): boolean {
    return (
      this.chartInfo !== null &&
      (
        this.chartInfo.total_fund !== 0 ||
        this.chartInfo.total_expense !== 0
      )
    )
  }

  get budgetBalance (): number {
    if (this.chartInfo === null) return 0
    return this.chartInfo.total_fund - this.chartInfo.total_expense
  }

  get averageExpense (): number {
    if (this.chartInfo === null) return 0
    return Math.floor(
      this.chartInfo.total_expense / this.membersCount
    )
  }

  get fundChartBarHeight (): number {
    if (this.chartInfo === null) return 0

    let percentage = this.chartInfo.total_fund / this.chartInfo.total_expense
    percentage = Math.min(percentage, 1)
    percentage = Math.max(percentage, 0.01)

    return percentage * 100
  }

  get expenseChartBarHeight (): number {
    if (this.chartInfo === null) return 0

    let percentage = this.chartInfo.total_expense / this.chartInfo.total_fund
    percentage = Math.min(percentage, 1)
    percentage = Math.max(percentage, 0.01)

    return percentage * 100
  }

  /**
   * Floating action button
   */
  fabOpen = false

  fabItems = [
    {
      icon: 'currency-usd',
      text: 'Thêm chuyển khoản',
      color: '#2A369C',
      dialogName: 'transferDialog'
    },
    {
      icon: 'piggy-bank',
      text: 'Thêm khoản góp',
      color: '#69AC61',
      dialogName: 'fundDialog'
    },
    {
      icon: 'hand-coin',
      text: 'Thêm khoản chi',
      color: '#EB623D',
      dialogName: 'expenseDialog'
    }
  ]

  onClickFabItem (dialogName: string): void {
    this[dialogName] = true
  }

  /**
   * Thêm khoản góp
   */
  fundDialog = false
  addingToFund = false

  fundFrom: User['url'] | null = null
  fundAmount: number | null = null
  fundDescription = ''

  fundFromErrs: string[] = []
  fundAmountErrs: string[] = []
  fundDescriptionErrs: string[] = []

  get enableAddFundBtn (): boolean {
    return this.fundFrom !== null && this.fundAmount !== null
  }

  addToFund (): void {
    const payload: TransactionCreateReq = {
      event: this.event.url,
      transaction_type: 'user_to_fund',
      from_user: this.fundFrom,
      to_user: null,
      amount: this.fundAmount,
      description: this.fundDescription
    }

    const successHandler = () => {
      this.fundDialog = false
      this.fundFrom = null
      // this.fundAmount = null // NOTE: don't reset `fundAmount` for convenience: most of the time every one pay to fund the same amount
      this.fundDescription = ''
      this.fundFromErrs = []
      this.fundAmountErrs = []
      this.fundDescriptionErrs = []
    }

    // @ts-expect-error don't care
    const badRequestHandler = err => {
      const data = err.response.data
      Object.entries(data).forEach(([field, errMsgs]) => {
        let attr = ''
        if (field === 'from_user') attr = 'fundFromErrs'
        else if (field === 'amount') attr = 'fundAmountErrs'
        else if (field === 'description') attr = 'fundDescriptionErrs'
        if (attr !== '') {
          this[attr] = errMsgs
        }
      })
    }

    this.addTransaction('addingToFund', payload, successHandler, badRequestHandler)
  }

  addTransaction (
    loadingAttr: string,
    payload: TransactionCreateReq,
    successHandler: CallableFunction,
    badRequestHandler: CallableFunction
  ): void {
    if (this[loadingAttr]) return
    this[loadingAttr] = true

    this.$store.dispatch('transaction/createTransaction', payload)
      .then(() => {
        successHandler()
        this.setupEventDetail()
      })
      .catch(err => {
        if (assertErrCode(err, status.HTTP_400_BAD_REQUEST)) {
          badRequestHandler(err)
        } else {
          unexpectedExc(err)
        }
      })
      .finally(() => {
        this[loadingAttr] = false
      })
  }

  /**
   * Thêm khoản chi
   */
  expenseDialog = false
  addingExpense = false
  expenseFromFund = true

  expenseFrom: User['url'] | null = null
  expenseAmount: number | null = null
  expenseDescription = ''

  expenseFromErrs: string[] = []
  expenseAmountErrs: string[] = []
  expenseDescriptionErrs: string[] = []

  get enableAddExpenseBtn (): boolean {
    return (
      this.expenseFromFund || this.expenseFrom !== null
    ) && this.expenseAmount != null
  }

  addExpense (): void {
    const transactionType = this.expenseFromFund ? 'fund_expense' : 'user_expense'

    const payload: TransactionCreateReq = {
      event: this.event.url,
      transaction_type: transactionType,
      from_user: transactionType === 'fund_expense' ? null : this.expenseFrom,
      to_user: null,
      amount: this.expenseAmount,
      description: this.expenseDescription
    }

    const successHandler = () => {
      this.expenseDialog = false
      this.expenseFrom = null
      this.expenseAmount = null
      this.expenseDescription = ''
      this.expenseFromErrs = []
      this.expenseAmountErrs = []
      this.expenseDescriptionErrs = []
    }

    // @ts-expect-error don't care
    const badRequestHandler = err => {
      const data = err.response.data
      Object.entries(data).forEach(([field, errMsgs]) => {
        let attr = ''
        if (field === 'from_user') attr = 'expenseFromErrs'
        else if (field === 'amount') attr = 'expenseAmountErrs'
        else if (field === 'description') attr = 'expenseDescriptionErrs'
        if (attr !== '') {
          this[attr] = errMsgs
        }
      })
    }

    this.addTransaction('addingExpense', payload, successHandler, badRequestHandler)
  }

  /**
   * Thêm chuyển khoản
   */
  transferDialog = false
  addingTransfer = false

  transferFrom: User['url'] | null = null
  transferTo: User['url'] | null = null
  transferAmount: number | null = null
  transferDescription = ''

  transferFromErrs: string[] = []
  transferToErrs: string[] = []
  transferAmountErrs: string[] = []
  transferDescriptionErrs: string[] = []

  get transferFromItems (): User[] {
    const items = this.event.members.filter(member => this.transferTo === null || this.transferTo !== member.url)
    const specialItem = {
      url: 'special_value_for_fund_to_user',
      nickname: 'Quỹ'
    }
    // @ts-expect-error `specialItem` don't have all attr of `User` but that's fine, `url` and `nickname` is all we need
    items.push(specialItem)
    return items
  }

  get transferToItems (): User[] {
    return this.event.members.filter(member => this.transferFrom === null || this.transferFrom !== member.url)
  }

  get enableAddTransferBtn (): boolean {
    return (
      this.transferFrom !== null &&
      this.transferTo !== null &&
      this.transferAmount !== null
    )
  }

  addTransfer (): void {
    const transactionType = this.transferFrom === 'special_value_for_fund_to_user' ? 'fund_to_user' : 'user_to_user'

    const payload: TransactionCreateReq = {
      event: this.event.url,
      transaction_type: transactionType,
      from_user: transactionType === 'fund_to_user' ? null : this.transferFrom,
      to_user: this.transferTo,
      amount: this.transferAmount,
      description: this.transferDescription
    }

    const successHandler = () => {
      this.transferDialog = false
      this.transferFrom = null
      this.transferTo = null
      this.transferAmount = null
      this.transferDescription = ''
      this.transferFromErrs = []
      this.transferToErrs = []
      this.transferAmountErrs = []
      this.transferDescriptionErrs = []
    }

    // @ts-expect-error don't care
    const badRequestHandler = err => {
      const data = err.response.data
      Object.entries(data).forEach(([field, errMsgs]) => {
        let attr = ''
        if (field === 'from_user') attr = 'transferFromErrs'
        if (field === 'to_user') attr = 'transferToErrs'
        else if (field === 'amount') attr = 'transferAmountErrs'
        else if (field === 'description') attr = 'transferDescriptionErrs'
        if (attr !== '') {
          this[attr] = errMsgs
        }
      })
    }

    this.addTransaction('addingTransfer', payload, successHandler, badRequestHandler)
  }

  /**
   * Recent transactions
   */
  loadingRecentTransactions = false
  recentTransactions: Transaction[] = []

  setupRecentTransactions (): void {
    this.loadingRecentTransactions = true

    Vue.axios.get(this.event.transactions_url, {
      params: {
        limit: 5
      }
    })
      .then(res => {
        this.recentTransactions = res.data.results
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loadingRecentTransactions = false
      })
  }

  get displayedRecentTransactions (): {
    created: string;
    amount: string;
    description: string;
    icon: string;
    text: string;
    color: string;
  }[] {
    return this.recentTransactions.map(transaction => {
      let icon = ''
      let text = ''
      let color = ''

      switch (transaction.transaction_type) {
        case 'user_to_user':
          icon = 'currency-usd'
          color = '#2A369C'
          // @ts-expect-error transaction of type "user_to_user" must have from_user and to_user
          text = `${transaction.from_user.nickname} đưa ${transaction.to_user.nickname}`
          break;

        case 'user_to_fund':
          icon = 'piggy-bank'
          color = '#69AC61'
          // @ts-expect-error transaction of type "user_to_fund" must have from_user
          text = `${transaction.from_user.nickname} góp quỹ`
          break;

        case 'fund_to_user':
          icon = 'currency-usd'
          color = '#2A369C'
          // @ts-expect-error transaction of type "fund_to_user" must have to_user
          text = `${transaction.to_user.nickname} nhận của quỹ`
          break;

        case 'user_expense':
          icon = 'hand-coin'
          color = '#EB623D'
          // @ts-expect-error transaction of type "user_expense" must have from_user
          text = `${transaction.from_user.nickname} chi`
          break;

        case 'fund_expense':
          icon = 'hand-coin'
          color = '#EB623D'
          text = 'Quỹ chi trả'
          break;

        default:
          break;
      }

      const displayed = {
        created: formatDatetime(transaction.create_time),
        amount: transaction.amount.toLocaleString() + ' đ',
        description: transaction.description,
        icon,
        text,
        color
      }

      return displayed
    })
  }

  /**
   * Chốt sổ
   */
  settlements: SettlementDetailRes[] = []
  loadingSettlements = false
  settlementsPagination: PaginatedRes | null = null
  itemsPerPage = 10
  settlementPage = 1

  get settlementsPaginationLength (): number {
    if (this.settlementsPagination !== null) {
      return Math.ceil(this.settlementsPagination.count / this.itemsPerPage)
    } else {
      return 0
    }
  }

  @Watch('settlementPage')
  onSettlementPageChange (pageNum: number): void {
    const offset = (pageNum - 1) * this.itemsPerPage
    const params = {
      limit: this.itemsPerPage,
      offset
    }
    this.setupSettlements(params)
  }

  nextPageSettlement (): void {
    if (this.settlementsPagination !== null && this.settlementsPagination.next !== null) {
      this.loading = true
      Vue.axios.get(this.settlementsPagination.next)
        .catch(unexpectedExc)
        .finally(() => {
          this.loading = false
        })
    }
  }

  previousPageSettlement (): void {
    if (this.settlementsPagination !== null && this.settlementsPagination.previous !== null) {
      this.loading = true
      Vue.axios.get(this.settlementsPagination.previous)
        .catch(unexpectedExc)
        .finally(() => {
          this.loading = false
        })
    }
  }

  setupSettlements (params?: AxiosRequestConfig['params']): void {
    this.loadingSettlements = true

    Vue.axios.get(this.event.settlements_url, { params })
      .then(res => {
        const data = (res.data as SettlementListRes)
        this.settlements = data.results || []
        delete data.results
        this.settlementsPagination = data
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loadingSettlements = false
      })
  }

  settlementToUpdate: SettlementDetailRes | null = null
  updatingSettlement = false
  paySettleConfirmDialog = false
  unpaySettleConfirmDialog = false
  showSuccess!: CallableFunction

  preparePaySettle (settlement: SettlementDetailRes): void {
    this.settlementToUpdate = settlement
    this.paySettleConfirmDialog = true
  }

  prepareUnpaySettle (settlement: SettlementDetailRes): void {
    this.settlementToUpdate = settlement
    this.unpaySettleConfirmDialog = true
  }

  paySettlement (): void {
    this.updateSettlement(true)
  }

  unpaySettlement (): void {
    this.updateSettlement(false)
  }

  updateSettlement (isPaid: boolean): void {
    if (this.updatingSettlement || this.settlementToUpdate === null) return
    this.updatingSettlement = true

    Vue.axios.patch(this.settlementToUpdate.url, {
      is_paid: isPaid
    })
      .then(() => {
        this.showSuccess('Cập nhật thành công.')
        this.paySettleConfirmDialog = false
        this.unpaySettleConfirmDialog = false
        // @ts-expect-error `settlementToUpdate` can't be null here
        const settlement = this.settlements.find(s => s.pk === this.settlementToUpdate.pk)
        if (settlement !== undefined) {
          settlement.is_paid = isPaid
        }
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.updatingSettlement = false
      })
  }

  /**
   * Delete event
   */
  deletingEvent = false
  confirmDeleteEventDialog = false

  deleteEvent (): void {
    if (this.deletingEvent) return
    this.deletingEvent = true

    this.$store.dispatch('event/deleteEvent', this.pk)
      .then(() => {
        this.showSuccess('Xóa chuyến đi thành công.')
        this.confirmDeleteEventDialog = false
        this.$router.push({ name: 'DashBoard' })
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.deletingEvent = false
      })
  }
}
