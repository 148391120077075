

































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseDialogConfirm extends Vue {
  @Prop(Boolean) readonly value!: boolean
  @Prop(Boolean) readonly loading!: boolean

  get localValue (): boolean {
    return this.value
  }

  set localValue (value: boolean) {
    this.$emit('input', value)
  }
}
