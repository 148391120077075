var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v(" mdi-arrow-left-thin ")])],1)],1),_c('h1',{staticClass:"text-h5 font-weight-bold mb-4"},[_vm._v(" Thành viên ")]),_c('v-sheet',{staticClass:"pa-2 mt-2",attrs:{"color":"#F4F5F7"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Thành viên đã tham gia ")]),_c('v-card-text',[(_vm.loadingEvent)?_c('v-skeleton-loader',{attrs:{"type":"paragraph"}}):(_vm.event.members.length === 0)?_c('div',[_vm._v(" Chưa có thành viên nào. ")]):_c('div',[_c('v-list',_vm._l((_vm.event.members),function(member){return _c('v-list-item',{key:member.pk,staticClass:"px-0"},[_c('v-list-item-avatar',[_c('BaseAvatar',{attrs:{"user":member}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(member.nickname)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(member.email)+" ")])],1),_c('v-list-item-icon',[_c('v-menu',{attrs:{"left":"","nudge-left":"40"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":member.pk === _vm.loggedInUser.pk}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.prepareRemove(member)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-account-remove-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Xóa khỏi chuyến đi ")])],1)],1)],1)],1)],1)],1)}),1)],1)],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Mời thêm ")]),_c('v-card-text',[_c('v-autocomplete',{ref:"searchInput",attrs:{"items":_vm.autocompleteItems,"search-input":_vm.text,"loading":_vm.searching && _vm.fetchedUsers.length === 0,"placeholder":"Nhập email để tìm kiếm","prepend-inner-icon":"mdi-magnify","outlined":"","clearable":"","hide-details":"","no-data-text":"Không có kết quả nào."},on:{"update:searchInput":function($event){_vm.text=$event},"update:search-input":function($event){_vm.text=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('BaseAvatar',{attrs:{"user":item.value,"size":"36"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.value.email)+" ")])],1)]}}]),model:{value:(_vm.chosenUser),callback:function ($$v) {_vm.chosenUser=$$v},expression:"chosenUser"}})],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Lời mời đã gửi ")]),_c('v-card-text',[(_vm.loadingInvitations)?_c('v-skeleton-loader',{attrs:{"type":"paragraph"}}):(_vm.invitations.length === 0)?_c('div',[_vm._v(" Không có lời mời nào. ")]):_c('v-list',_vm._l((_vm.invitations),function(invitation){return _c('v-list-item',{key:invitation.pk,staticClass:"px-0"},[_c('v-list-item-avatar',[_c('BaseAvatar',{attrs:{"user":invitation.user}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(invitation.user.nickname)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(invitation.user.email)+" ")])],1),_c('v-list-item-icon',[(invitation.status === 'pending')?_c('v-menu',{attrs:{"left":"","nudge-left":"40"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.prepareRemoveInvitation(invitation)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Hủy lời mời ")])],1)],1)],1)],1):(invitation.status === 'declined')?_c('v-chip',[_vm._v(" Đã từ chối ")]):_vm._e()],1)],1)}),1)],1)],1)],1),_c('BaseDialogConfirm',{attrs:{"loading":_vm.removing},on:{"confirm":_vm.removeMember,"cancel":function($event){_vm.confirmRemoveDialog = false}},model:{value:(_vm.confirmRemoveDialog),callback:function ($$v) {_vm.confirmRemoveDialog=$$v},expression:"confirmRemoveDialog"}},[_vm._v(" Xóa "),(_vm.memberToRemove !== null)?_c('strong',[_vm._v(_vm._s(_vm.memberToRemove.nickname))]):_vm._e(),_vm._v(" khỏi chuyến đi? ")]),_c('BaseDialogConfirm',{attrs:{"loading":_vm.removingInvitation},on:{"confirm":_vm.removeInvitation,"cancel":function($event){_vm.confirmRemoveInvitationDialog = false}},model:{value:(_vm.confirmRemoveInvitationDialog),callback:function ($$v) {_vm.confirmRemoveInvitationDialog=$$v},expression:"confirmRemoveInvitationDialog"}},[_vm._v(" Hủy lời mời đối với "),(_vm.invitationToRemove !== null)?_c('strong',[_vm._v(_vm._s(_vm.invitationToRemove.user.nickname))]):_vm._e(),_vm._v("? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }