
























import { Api } from '@/api'
import { FbDataDeletionStatusRes } from '@/interfaces/api/account'
import { formatDatetime, unexpectedExc } from '@/utils'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class FbDataDeletionStatus extends Vue {
  loading = true

  status: FbDataDeletionStatusRes = {
    confirmation_code: '',
    status: '',
    issued_at: '',
    expires: ''
  }

  created (): void {
    this.loading = true

    const code = (this.$route.query.code || '') as string
    Api.account.getFbDataDeletionStatus(code)
      .then(data => {
        this.status = data
      })
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }

  getStatusText (status: string): string {
    const mapper: { [index: string]: string } = {
      pending: 'Đang xử lý',
      success: 'Thành công',
      fail: 'Thất bại'
    }
    return mapper[status] || ''
  }

  formatDatetime = formatDatetime
}
