
























































































































































import { Event } from '@/interfaces/event'
import { EventInvitation, User } from '@/interfaces/user'
import { unexpectedExc } from '@/utils'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import BaseAvatar from '@/components/BaseAvatar.vue'
import { PaginatedRes } from '@/interfaces/api/common'
import { AxiosRequestConfig } from 'axios'

@Component({
  computed: {
    ...mapState('account', {
      user: 'loggedInUser',
      eventInvitations: 'eventInvitations'
    }),
    ...mapState('event', [
      'events',
      'pagination'
    ])
  },
  components: {
    BaseAvatar
  }
})
export default class DashBoard extends Vue {
  /**
   * Setup
   */
  events!: Event[]
  user!: User
  eventInvitations!: EventInvitation[]
  loading = true

  created (): void {
    this.setupEvents()
    this.setupNotifications()
  }

  setupEvents (url?: string, params?: AxiosRequestConfig['params']): void {
    this.loading = true

    this.$store.dispatch('event/getEvents', { params, url })
      .catch(unexpectedExc)
      .finally(() => {
        this.loading = false
      })
  }

  setupNotifications (): void {
    this.$store.dispatch('account/getEventInvitations')
      .catch(unexpectedExc)
  }

  get pendingEventInvitationsCount (): number {
    return this.eventInvitations.filter(invitation => invitation.status === 'pending').length
  }

  /**
   * List item display
   */
  getTruncatedMembers (event: Event): Event['members'] {
    return event.members.slice(0, 3)
  }

  getRemainingMembersCount (event: Event): number {
    const truncatedMembers = this.getTruncatedMembers(event)
    return event.members.length - truncatedMembers.length
  }

  /**
   * Pagination
   */
  page = 1
  pagination!: PaginatedRes
  itemsPerPage = 10

  get paginationLength (): number {
    return Math.ceil(this.pagination.count / this.itemsPerPage)
  }

  @Watch('page')
  onPageChange (pageNum: number): void {
    const offset = (pageNum - 1) * this.itemsPerPage
    const params = {
      limit: this.itemsPerPage,
      offset
    }
    this.setupEvents(undefined, params)
  }

  nextPage (): void {
    if (this.pagination.next !== null) {
      this.setupEvents(this.pagination.next)
    }
  }

  previousPage (): void {
    if (this.pagination.previous !== null) {
      this.setupEvents(this.pagination.previous)
    }
  }
}
